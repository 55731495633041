import { extendTailwindMerge } from "tailwind-merge";

// -----------------------------------------------------------------------------

const fontSizes = ["2xs", "xs", "sm", "md", "h6", "h5", "h4", "h3", "h2", "h1"];

const spacings = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 25,
  26, 28, 29, 30, 32, 34, 36, 38, 39, 40, 41, 44, 48, 50, 52, 54, 56, 60, 64,
  65, 66, 72, 80, 90, 92, 96, 100, 108, 110, 112, 120, 128, 130, 140, 144, 160, 176,
  192, 200, 208, 210, 224, 240, 254, 256, 270, 280, 288, 290, 300, 320, 325,
  350, 380, 384, 390, 400, 410, 450, 450, 466, 560, 600, 624, 708, 800, 850,
  1200, 1700,
];

export const spacing = Object.fromEntries(
  [[0, "0px"]].concat(spacings.map((s) => [`${s}px`, `${s / 16}rem`])),
);

// -----------------------------------------------------------------------------

/** Over-customization breaks defaults, extending is neccessary */
export const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      spacing: [0].concat(spacings.map((s) => `${s}px`)),
    },
    classGroups: {
      "font-size": [{ text: fontSizes }],
      leading: [{ leading: ["none", "tight", ...fontSizes] }],
    },
    conflictingClassGroups: {
      "font-size": ["leading"],
    },
    conflictingClassGroupModifiers: {
      "font-size": ["leading"],
    },
  },
});
